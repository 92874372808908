<template>
  <section class="w-100">
    <LoadingSpinner v-if="loading" />
    <b-container class="py-md-4">
      <div
        :class="['register_wrapper', 'my-md-5 my-4 p-3']"
        :style="callType === '2' && accountType === '2' && userId == details.celebrity_id ? 'height:500px; margin-top:15%;' : ''"
      >
        <b-row
          class="justify-content-center"
          :style="callType === '2' && accountType === '2' && userId == details.celebrity_id ? 'margin-top:15%;' : ''"
        >
          <b-col
            xl="6"
            lg="8"
          >
            <template v-if="seconds == 0 && minutes == 0">
              <div class="text-center">
                <p class="h4 font-weight-bold">
                  {{ accountType === '2' && callType === "2" && userId == details.celebrity_id ? `Your stream with fans will end in` : callType === "1" && accountType === "2" && userId == details.celebrity_id ? `Your videocall with fans will end in` : accountType === '1' && callType === "1" || accountType === '2' && callType === "1" && userId != details.celebrity_id ? `Your videocall with ${gabName} will end in`: `Your stream with ${gabName} will end in` }}
                </p>
              </div>
              <p class="display-4 font-weight-bold text-center">
                {{ minsDuration.toString().length === 2 ? minsDuration : `0${minsDuration}` }}:{{ secsDuration.toString().length === 2 ? secsDuration : `0${secsDuration}` }}
              </p>
            </template>
            <template v-else>
              <div class="text-center">
                <p class="h4 font-weight-bold">
                  {{ accountType === '2' && callType === "2" && userId == details.celebrity_id ? `Your stream with fans` : callType === "1" && accountType === "2" && userId == details.celebrity_id ? `Your videocall with fan` : accountType === '1' && callType === "1" || accountType === '2' && callType === "1" && userId != details.celebrity_id ? `Your videocall with ${gabName}`: `Your stream with ${gabName}` }}
                </p>
              </div>
              <p class="display-4 font-weight-bold text-center">
                {{ minutes.toString().length === 2 ? minutes : `0${minutes}` }}:{{ seconds.toString().length === 2 ? seconds : `0${seconds}` }}
              </p>
            </template>
            <div class="text-center">
              <img
                v-if="fabPhoto !== ''"
                :src="fabPhoto"
                style="width: 70%; height: 300px"
              >
              <b-button
                ref="button"
                variant="dark"
                :class="['btn-pop text-uppercase font-weight-bold mt-4']"
                squared
                style="width: 50%"
                block
                @click="zoomSignature"
              >
                {{ accountType === '1' || accountType === '2' && userId != details.celebrity_id ? 'Join' : 'Start' }}  {{ callType === "2" ? 'Stream' : 'Videocall' }}
              </b-button>

              <p
                v-if="accountType === '2' && userId == details.celebrity_id"
                class="font-weight-light mt-4"
              >
                {{ callType === "2" || callType === "1" && accountType === "2" && userId == details.celebrity_id ? 'Fans' : gabName }} will be able to join the {{ callType === "1" ? 'videocall' : 'stream' }} once you start
                it
              </p>
              <p
                v-else
                class="font-weight-light mt-4"
              >
                {{ gabName }} is ready to join it
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </section>
</template>

<script>
import { /*getSignature,*/ getMeetingDetails, showDays } from "../../api/meeting";
import { _sendResponse } from '../../js/common-function';
import LoadingSpinner from '../Common/LoadingSpinner';
import {REDIRECTION_URL, ZOOM_KEY, ZOOM_SECRET_KEY} from '../../config/config';
import moment from 'moment-timezone';

export default {
    name: "Call",
    components:{ LoadingSpinner },
    data() {
        return {
            accountType: localStorage.getItem('account-type').toString(),
            userId: localStorage.getItem('userId'),
            loading: false,
            url: null,
            email: "",
            showModal: false,
            isLoading: false,
            zoomDetails: {},
            fabPhoto: "",
            gabName: "",
            apiKey: ZOOM_KEY,
            apiSecretKey: ZOOM_SECRET_KEY,
            leaveUrl: REDIRECTION_URL,
            userName: localStorage.getItem('fullname'),
            meetingNumber: "",
            passWord: "",
            role: 0,
            callType: 1,
            userEmail: "",
            registrantToken: "",
            minutes:0,
            seconds:0,
            mainMin:0,
            minsDuration: 0,
            secsDuration: 0,
            meetingEndTime: "",
            details: null
        };
    },
    watch:{
        seconds:function(){
            setTimeout(()=>{
                if (this.seconds > 0){
                    this.seconds --;
                } else {
                    this.minutes --;
                    this.seconds = 59;
                }
            }, 1000);
        },
        minutes:function(){
            if (this.minutes < 0){
                this.minutes = 0;
                this.seconds = 0;
            }
        },
        secsDuration:function(){
            setTimeout(()=>{
                if (this.secsDuration > 0){
                    this.secsDuration --;
                } else {
                    this.minsDuration --;
                    this.secsDuration = 59;
                }
            }, 1000);
        },
        minsDuration:function(){
            if (this.minsDuration < 0){
                this.minsDuration = 0;
                this.secsDuration = 0;
            }
        }
    },
    async created() {
        this.loading = true;
        let bookingId = this.$route.params.id;
        let details = await getMeetingDetails(bookingId);
        if (details && details.length > 0){
            this.details = details ? details[0] : null;
            let start_time = new Date();
            let end_time = new Date(details[0].start_time.replace(/-/g, '/')+' UTC');
            let meeting_end_time = new Date(details[0].end_time.replace(/-/g, '/')+' UTC');
            this.meetingEndTime = meeting_end_time;
            let remainingTime = showDays(start_time, end_time, meeting_end_time);
            let time_left = remainingTime.time_left.split(':');
            this.mainMin = remainingTime.minutes;
            this.minutes = remainingTime.minutes.toString().includes('-') || this.mainMin === 0 ? 0 : parseInt(time_left[1]);
            this.seconds = remainingTime.minutes.toString().includes('-') || this.mainMin === 0  ? 0 : parseInt(time_left[2]);
            let durationLeft = remainingTime.call_time_left.split(':');
            this.minsDuration = remainingTime.call_duration_left.toString().includes('-') || this.mainMin === 0 ? 0 : parseInt(durationLeft[1]);
            this.secsDuration = remainingTime.call_duration_left.toString().includes('-') || this.mainMin === 0 ? 0 : parseInt(durationLeft[2]);

            this.loading = false;
            this.callType = details[0].call_type.toString();
            this.fabPhoto = this.callType === '2' || this.callType === '1' && this.accountType === '2'  &&  details[0].celebrity_id == this.userId ? '' : details[0].meet_with.profile_pic_url;
            this.gabName = details[0].meet_with.name + ' ' + details[0].meet_with.surname;
            this.meetingNumber = details[0].zoom_meeting_id;
            if (this.callType == 2){
                let steam_type = details[0].slot ? details[0].slot.stream_type : "";
                if (steam_type !== "" && steam_type === 1 && details[0].celebrity_id == this.userId){
                    this.password = details[0] ? details[0].zoom_meeting_password : "";
                }
                if (steam_type !== "" && steam_type === 2){
                    this.password = details[0] ? details[0].zoom_meeting_password : "";
                }
            }
            if (this.callType == 1){
                if (details[0].celebrity_id == this.userId){
                    this.password = details[0] ? details[0].zoom_meeting_password : "";
                }
            }
        }
    },
    methods: {
        async zoomSignature() {
            if (this.minutes === 0){
                this.loading = true;
                /*let signature = await getSignature(this.meetingNumber, this.role);
                if (signature !== undefined && signature.signature !== undefined) {
                    this.startMeeting(signature.signature);
                    this.loading = false;
                }*/
                let end = moment(this.meetingEndTime);
                let start_time = new Date();
                let current = moment(start_time);
                let sec = end.diff(current, 'seconds');
                if (sec <= 0){
                    _sendResponse('error','The meeting has expired');
                    this.$router.push('/meetings');
                } else {
                    this.startMeeting();
                }

            } else {
                _sendResponse('error',this.accountType === '2' ? `Please wait until your call starts. You can start your call on schedule time.` : `Please wait until your call starts. You can join your call on schedule time`);
            }
        },
        startMeeting() {
            const { ZoomMtg } = require("@zoomus/websdk");
            var signature = ZoomMtg.generateSignature({
                meetingNumber: this.meetingNumber,
                apiKey: this.apiKey,
                apiSecret: this.apiSecretKey,
                role: this.role
            });
            if (signature !== "") {
                this.loading = false;
            }
            ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
                // eslint-disable-next-line no-console
                console.log("inMeetingServiceListener onUserJoin", data);
            });
            ZoomMtg.setZoomJSLib(
                "https://source.zoom.us/1.9.9/lib",
                "/av"
            );
            ZoomMtg.preLoadWasm();
            ZoomMtg.prepareWebSDK();
            ZoomMtg.i18n.load("en-US");
            ZoomMtg.i18n.reload("en-US");

            ZoomMtg.init({
                leaveUrl: this.leaveUrl,
                disablePreview: true,
                success: (success) => {
                    // eslint-disable-next-line no-console
                    console.log(success);
                    ZoomMtg.join({
                        meetingNumber: this.meetingNumber,
                        userName: this.userName,
                        signature: signature,
                        apiKey: this.apiKey,
                        passWord: this.password,
                        // userEmail: this.userEmail,
                        // passWord: this.passWord,
                        success: (success) => {
                            // eslint-disable-next-line no-console
                            console.log(success);
                        },
                        error: (error) => {
                            // eslint-disable-next-line no-console
                            console.log(error);
                        },
                    });
                },
                error: (error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                },
            });
        },
    },
};
</script>
